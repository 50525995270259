@mixin for-phone-only {
    @media (max-width: 1200px) { @content; }
}

@mixin for-large-resolution {
    @media (min-width: 2560px) { @content; }
}

@mixin for-tablet-only {
    @media (min-width: 700px) and (max-width: 1200px) { @content; }
}
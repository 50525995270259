@font-face {
  font-family: One Day;
  src: url("ONEDAY.5dd0ea42.otf");
  src: url("ONEDAY.8e7016d5.ttf");
}

@font-face {
  font-family: Multilines;
  src: url("MultiTypeLines-Columns.e0d741fd.otf");
}

@font-face {
  font-family: Gotham Pro;
  src: url("gothampro.32d2bfc2.ttf"), url("gothampro_black.fa8fd762.ttf"), url("gothampro_blackitalic.e96d84ae.ttf"), url("gothampro_bold.7fed84b5.ttf"), url("gothampro_bolditalic.ae060710.ttf"), url("gothampro_italic.3b306ce3.ttf"), url("gothampro_light.1e6416ba.ttf"), url("gothampro_lightitalic.a5cacaea.ttf"), url("gothampro_medium.ab43dae8.ttf"), url("gothampro_mediumitalic.d2167881.ttf");
}

.form-overlay {
  z-index: 10;
  visibility: hidden;
  background-color: #000000e7;
  place-content: center;
  display: grid;
  position: fixed;
  inset: 0;
}

.form-overlay form {
  background-color: #130515f6;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 7vh;
  height: 70vh;
  padding: 5.2rem 1.5rem;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 14px #92f0f7;
}

@media (width <= 1200px) {
  .form-overlay form {
    gap: 2.7rem;
    height: 60vh;
  }
}

@media (width >= 2560px) {
  .form-overlay form {
    gap: 6vh;
    height: 63vh;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  .form-overlay form {
    gap: 60px;
  }
}

.form-overlay form .form-close-btn {
  background: none;
  justify-self: end;
  padding: 20px;
  line-height: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.form-overlay form .form-close-btn img {
  height: 2.6rem;
}

@media (width <= 1200px) {
  .form-overlay form .form-close-btn img {
    height: 30px;
  }
}

.form-overlay form .form-header {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.form-overlay form .form-header .switch {
  place-self: end;
  width: 16em;
  height: 3.3rem;
  display: inline-block;
  position: relative;
}

@media (width <= 1200px) {
  .form-overlay form .form-header .switch {
    width: 280px;
  }
}

.form-overlay form .form-header .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.form-overlay form .form-header .slider {
  cursor: pointer;
  color: #130515;
  background-color: #ccc;
  border-radius: .6rem;
  justify-content: end;
  align-items: center;
  padding: 0 1.4em 0 2.2em;
  transition: transform .4s;
  display: flex;
  position: absolute;
  inset: 0;
}

@media (width <= 1200px) {
  .form-overlay form .form-header .slider {
    padding: 0 2em 0 3em;
  }
}

.form-overlay form .form-header .slider.telegram-icon:before {
  content: "";
  background-image: url("telegram-icon.fc57c443.svg");
  background-size: 25%;
}

.form-overlay form .form-header .slider.email-icon:before {
  content: "";
  background-image: url("email-icon.f6a754ae.svg");
}

.form-overlay form .form-header .slider:before {
  content: "";
  content: attr(data-before);
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 8.8em;
  height: 85%;
  transition: transform .4s;
  display: flex;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

@media (width <= 1200px) {
  .form-overlay form .form-header .slider:before {
    width: 11em;
    bottom: 3px;
    left: 3px;
  }
}

@media (width >= 2560px) {
  .form-overlay form .form-header .slider:before {
    bottom: 7px;
    left: 7px;
  }
}

.form-overlay form .form-header input:checked + .slider {
  background-color: #ccc;
}

.form-overlay form .form-header input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

.form-overlay form .form-header input:checked + .slider:before {
  transform: translateX(6.7em);
}

@media (width <= 1200px) {
  .form-overlay form .form-header input:checked + .slider:before {
    transform: translateX(8.5em);
  }
}

.form-overlay form .form-input-container {
  flex-direction: column;
  gap: 1.7em;
  display: flex;
}

@media (width >= 700px) and (width <= 1200px) {
  .form-overlay form .form-input-container {
    gap: 30px;
  }
}

.form-overlay form .form-input-container label.error .form-input {
  border: 2px solid red;
}

.form-overlay form .form-input-container label.error .error-text {
  color: red;
  display: block;
  position: absolute;
}

.form-overlay form .form-input-container .error-text {
  margin-top: 5px;
  display: none;
}

.form-overlay form .form-input-container .form-input {
  color: #130515;
  border-radius: .6rem;
  width: 28vw;
  padding: .5rem 1.6rem;
  font-family: Gotham Pro;
  font-size: 1.2em;
  line-height: 2em;
}

@media (width <= 1200px) {
  .form-overlay form .form-input-container .form-input {
    width: 280px;
    font-size: 12px;
    line-height: 35px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  .form-overlay form .form-input-container .form-input {
    width: 500px;
    padding: 12px 26px;
    font-size: 20px;
  }
}

.form-overlay form .form-submit-container {
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  display: flex;
}

@media (width >= 700px) and (width <= 1200px) {
  .form-overlay form .form-submit-container {
    gap: 30px;
  }
}

.form-overlay form .form-submit-container .form-submit-btn {
  letter-spacing: .24px;
  color: #130515;
  background: #ccc;
  border-radius: .6rem;
  max-width: 11rem;
  padding: .5rem 1.4rem;
  font-size: 1.1em;
  line-height: 2em;
  transition: color .3s ease-out;
}

@media (width <= 1200px) {
  .form-overlay form .form-submit-container .form-submit-btn {
    padding: 4px 14px;
    font-size: 12px;
    line-height: 35px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  .form-overlay form .form-submit-container .form-submit-btn {
    max-width: 220px;
    padding: 8px 24px;
    font-size: 18px;
  }
}

.form-overlay form .form-submit-container .form-submit-btn:hover {
  color: #fff;
  background: linear-gradient(90deg, #ed20d2 0%, #31d7da 87%);
}

.form-overlay form .form-submit-container .form-tg-link {
  text-align: center;
  letter-spacing: .24px;
  white-space: nowrap;
  font-weight: bold;
  line-height: 2em;
  display: none;
}

@media (width <= 1200px) {
  .form-overlay form .form-submit-container .form-tg-link {
    line-height: 35px;
  }
}

.form-overlay form .form-submit-container label {
  text-align: center;
  max-width: 30vw;
  font-size: .8em;
}

@media (width <= 1200px) {
  .form-overlay form .form-submit-container label {
    max-width: 400px;
    font-size: 10px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  .form-overlay form .form-submit-container label {
    font-size: 14px;
  }
}

.form-overlay.form-active {
  visibility: visible;
}

.form-overlay.form-tg-mode form {
  gap: 5vh;
}

.form-overlay.form-tg-mode form .form-input-container {
  display: none;
}

.form-overlay.form-tg-mode form .form-submit-container {
  gap: 2em;
}

.form-overlay.form-tg-mode form .form-submit-container .email-submit-btn {
  display: none;
}

.form-overlay.form-tg-mode form .form-submit-container .form-tg-link {
  display: block;
}

@property --a {
  syntax: "<angle>";
  inherits: false;
  initial-value: 10deg;
}

@property --p {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 20%;
}

@property --c {
  syntax: "<color>";
  inherits: false;
  initial-value: #ed20d2;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  color: #fff;
  font-family: Gotham Pro;
}

@media (width <= 1200px) {
  html {
    font-size: 14px;
  }
}

@media (width >= 2560px) {
  html {
    font-size: 26px;
  }
}

body {
  background-color: #130515;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: #fff0;
  margin-top: 5px;
  margin-bottom: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: #cae0e8;
  border: none;
  border-radius: 10px;
}

button {
  letter-spacing: .24px;
  white-space: nowrap;
  color: #fff;
  --a: 10deg;
  --c: #ed20d2;
  background: --c;
  background: linear-gradient(90deg, var(--c), #31d7da 87%);
  border: none;
  border-radius: 2em;
  padding: .8rem 2.6rem;
  font-family: Gotham Pro;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5em;
  transition: --a .5s, --c .5s;
}

@media (width <= 1200px) {
  button {
    max-width: 320px;
    padding: .8rem 1.8rem;
    font-size: 16px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  button {
    max-width: 400px;
    padding: 1.3rem 2.8rem;
    font-size: 20px;
  }
}

@media (width >= 2560px) {
  button {
    font-size: 38px;
  }
}

button:hover {
  cursor: pointer;
  --c: #4ac41c;
}

a {
  text-decoration: none;
}

.main-container {
  z-index: 0;
  position: relative;
}

header {
  background-color: #130515;
  grid-template-rows: 1fr;
  grid-template-columns: 10fr 1fr 1fr;
  align-items: center;
  gap: 3.8rem;
  min-width: 100%;
  padding: 1.2rem;
  font-size: 1.2rem;
  display: grid;
}

@media (width <= 1200px) {
  header {
    gap: 1.8rem;
    padding: 1rem;
  }
}

header a {
  justify-self: start;
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media (width <= 1200px) {
  header a {
    gap: 8px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  header a {
    gap: 16px;
  }
}

header a img {
  height: 3rem;
}

@media (width <= 1200px) {
  header a img {
    height: 28px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  header a img {
    height: 48px;
  }
}

header a span {
  color: #cae0e8;
  min-width: 200px;
  font-family: One Day;
  font-size: 1.6rem;
  font-weight: bold;
}

@media (width <= 1200px) {
  header a span {
    min-width: 100px;
    font-size: 11px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  header a span {
    font-size: 20px;
  }
}

header button {
  background: none;
  place-self: center end;
  padding: 0;
  display: flex;
}

header button img {
  height: 2.5rem;
}

@media (width <= 1200px) {
  header button img {
    height: 20px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  header button img {
    height: 40px;
  }
}

header .logo-link {
  padding-left: 2.5rem;
}

@media (width <= 1200px) {
  header .logo-link {
    padding-left: 18px;
  }
}

header .languages-container {
  flex-direction: column;
  justify-self: end;
  align-items: center;
  display: flex;
}

header .languages-container ul {
  z-index: 100;
  visibility: hidden;
  background-color: #5c207d40;
  border-radius: 1.1rem;
  flex-direction: column;
  gap: 1.1rem;
  padding: 1.1rem 1.7rem;
  font-size: 1.1rem;
  list-style: none;
  display: flex;
  position: absolute;
  top: 5rem;
}

@media (width <= 1200px) {
  header .languages-container ul {
    font-size: 9px;
    top: 50px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  header .languages-container ul {
    font-size: 18px;
    top: 80px;
  }
}

header .languages-container ul li {
  padding: 0 px;
}

header .languages-container ul li a {
  color: #fff;
  padding-left: 0;
}

nav {
  z-index: 1;
  visibility: hidden;
  background-color: #130515;
  grid-template-rows: 3fr 1fr;
  grid-template-columns: 1fr;
  min-width: 100%;
  min-height: 100%;
  padding: 20px 100px;
  display: grid;
  position: fixed;
  top: 8vh;
  overflow-y: auto;
}

@media (width <= 1200px) {
  nav {
    padding: 0 18px;
    top: 5vh;
  }
}

nav a {
  color: #fff;
}

nav ul {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 70px;
  list-style: none;
  display: flex;
}

@media (width <= 1200px) {
  nav ul {
    gap: 55px;
  }
}

nav ul a {
  padding-left: 20px;
  font-size: 2.8rem;
}

@media (width <= 1200px) {
  nav ul a {
    padding-left: 8px;
    font-size: 2.2rem;
  }
}

nav ul a:hover {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(right, #31d7da, #ed20d2) text;
}

nav ul li img {
  opacity: 0;
}

@media (width <= 1200px) {
  nav ul li img {
    height: 17px;
  }
}

nav ul li .navlink-gradient2:hover {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#4ac41c", endColorstr= "#31d7da", GradientType= 1);
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #4ac41c 2%, #31d7da 100%) text;
}

nav ul li .navlink-gradient3:hover {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ff8e0c", endColorstr= "#31d7da", GradientType= 1);
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #ff8e0c 22%, #31d7da 100%) text;
}

nav ul li:hover img {
  opacity: 1;
}

nav .nav-footer {
  justify-self: end;
  height: 90px;
  font-size: 1.4rem;
}

nav .nav-footer a {
  flex-direction: row;
  align-items: center;
  gap: 14px;
  display: flex;
}

@media (width <= 1200px) {
  nav .nav-footer a {
    gap: 9px;
  }
}

nav .nav-footer a img {
  height: 36px;
}

@media (width <= 1200px) {
  nav .nav-footer a img {
    height: 24px;
  }
}

main {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  min-height: 92vh;
  padding: 20px 20px 40px 4%;
  display: flex;
}

@media (width <= 1200px) {
  main {
    flex-direction: column;
    justify-content: space-around;
    gap: 60px;
    padding: 50px 20px 20px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  main {
    padding-top: 100px;
  }
}

main .main-text-container {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  gap: 2.4rem;
  max-width: 44vw;
  display: flex;
}

@media (width <= 1200px) {
  main .main-text-container {
    align-items: center;
    max-width: 90%;
  }
}

@media (width >= 2560px) {
  main .main-text-container {
    max-width: 1200px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  main .main-text-container {
    gap: 4em;
    font-size: 20px;
  }
}

main .main-text-container .main-heading-container {
  height: 100px;
  margin-bottom: 100px;
}

@media (width >= 2560px) {
  main .main-text-container .main-heading-container {
    margin-bottom: 260px;
  }
}

main .main-text-container .main-heading-container h1 {
  font-size: 2.8em;
}

@media (width <= 1200px) {
  main .main-text-container .main-heading-container h1 {
    font-size: 2em;
  }
}

main .main-text-container .main-heading-container span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(right, #31d7da, #ed20d2) text;
  font-size: 2.8em;
  font-weight: bold;
}

@media (width <= 1200px) {
  main .main-text-container .main-heading-container span {
    font-size: 2em;
  }
}

main .main-text-container .main-heading-container span.typed-style-0 {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(right, #31d7da, #ed20d2) text;
}

main .main-text-container .main-heading-container span.typed-style-1 {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(right, #4ac41c, #31d7da) text;
}

main .main-text-container .main-heading-container span.typed-style-2 {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(right, #ff8e0c, #31d7da) text;
}

main .main-text-container p {
  font-optical-sizing: auto;
  font-size: 1.3em;
}

@media (width <= 1200px) {
  main .main-text-container p {
    text-align: center;
  }
}

main .main-text-container .main-register-btn {
  --с: #ed20d2;
  --p: 20%;
  background: --c;
  background-size: 100%;
  background: linear-gradient(90deg, var(--c) 0%, #31d7da 87%);
  background: -moz-linear-gradient(90deg, --c 0%, #31d7da 87%);
  background: -webkit-linear-gradient(90deg, --c 0%, #31d7da 87%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#4ac41c", endColorstr= "#31d7da", GradientType= 1);
  animation: 20s infinite --c;
}

@media (width <= 1200px) {
  main .main-text-container .main-register-btn {
    font-size: 13px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  main .main-text-container .main-register-btn {
    max-width: 500px;
    font-size: 20px;
  }
}

main .main-text-container .main-register-btn.main-btn-style-0 {
  --c: #ed20d2;
  background: --c;
  background: linear-gradient(90deg, var(--c) 0%, #31d7da 87%);
}

main .main-text-container .main-register-btn.main-btn-style-1 {
  --c: #4ac41c;
  background: --c;
  background: linear-gradient(90deg, var(--c) 2%, #31d7da 100%);
}

main .main-text-container .main-register-btn.main-btn-style-2 {
  --c: #ff8e0c;
  background: --c;
  background: linear-gradient(90deg, var(--c) 22%, #31d7da 100%);
}

main .video-overlay {
  opacity: 1;
  place-content: center;
  align-items: center;
  width: 51.5%;
  height: 60vh;
  position: absolute;
  top: 18%;
  left: 26%;
  overflow: hidden;
}

main .video-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 80px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width <= 1200px) {
  main .video-container {
    max-width: 90%;
  }
}

main .video-container img {
  width: 100%;
  max-height: 60vh;
}

@media (width <= 1200px) {
  main .video-container img {
    height: auto;
    overflow-x: hidden;
  }
}

main .video-container iframe {
  width: 52%;
  height: 52%;
  position: absolute;
  top: 72px;
  left: 180px;
}

@media (width <= 1200px) {
  main .video-container iframe {
    top: 40px;
    left: 90px;
  }
}

section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  display: flex;
}

@media (width <= 1200px) {
  section {
    padding: 40px 18px;
  }
}

section h2 {
  text-align: center;
  font-size: 2.6em;
}

@media (width <= 1200px) {
  section h2 {
    font-size: 1.9em;
  }
}

section h3 {
  font-size: 1.8em;
}

section img {
  max-width: 40vw;
}

.awards-section {
  background-color: #000;
  flex-flow: wrap;
  justify-content: space-around;
  padding: 5% 8% 2%;
}

@media (width <= 1200px) {
  .awards-section {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 3rem 4rem;
    display: grid;
  }
}

.awards-section img {
  width: 5rem;
}

@media (width <= 1200px) {
  .awards-section img {
    justify-self: center;
    width: 60px;
  }
}

.benefits-section-short {
  background-color: #000;
  gap: 6rem;
}

@media (width <= 1200px) {
  .benefits-section-short {
    gap: 4rem;
  }
}

.benefits-section-short .benefits-short-container {
  text-align: center;
  color: #000;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 2.3rem;
  display: grid;
}

@media (width <= 1200px) {
  .benefits-section-short .benefits-short-container {
    flex-direction: column;
    width: 80%;
    display: flex;
  }
}

.benefits-section-short .benefits-short-container div {
  background-color: #fff;
  border-radius: 1em;
  align-self: center;
  width: 18vw;
  height: 160px;
  padding: 2rem 1rem;
  transition: all .3s ease-in;
  position: relative;
  bottom: 0;
  right: 0;
}

@media (width <= 1200px) {
  .benefits-section-short .benefits-short-container div {
    width: 100%;
    height: 130px;
    padding: 2.5rem 1rem;
  }
}

@media (width >= 2560px) {
  .benefits-section-short .benefits-short-container div {
    height: 200px;
    font-size: .7em;
  }
}

.benefits-section-short .benefits-short-container div svg {
  height: 3.4rem;
  position: absolute;
  bottom: .1rem;
  right: .4rem;
}

@media (width <= 1200px) {
  .benefits-section-short .benefits-short-container div svg {
    height: 4rem;
  }
}

@media (width >= 2560px) {
  .benefits-section-short .benefits-short-container div svg {
    height: 2.7rem;
  }
}

.benefits-section-short .benefits-short-container div:hover {
  color: #b0109af3;
  position: relative;
  bottom: 8px;
  right: 8px;
  box-shadow: 5px 5px 26px #92f0f7;
}

.benefits-section-short .benefits-short-container div:hover svg {
  fill: #53b1b3bd;
  stroke: #53b1b3bd;
}

.benefits-section-short .benefits-short-container div:hover svg g {
  fill: #53b1b3bd;
}

.benefits-section-short .benefits-short-container div:hover .trend-svg path {
  stroke: #53b1b3bd;
}

.benefits-long-section .benefits-long-container {
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  padding: 3.8rem 0;
  display: flex;
}

.benefits-long-section .benefits-long-container .benefit-card {
  color: #130515;
  background-color: #fff;
  border-radius: 1.5rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  max-width: 74vw;
  padding: 2rem;
  display: flex;
}

@media (width <= 1200px) {
  .benefits-long-section .benefits-long-container .benefit-card {
    flex-direction: column;
  }
}

.benefits-long-section .benefits-long-container .benefit-card img {
  border-radius: 1.5rem;
  height: 56vh;
}

@media (width <= 1200px) {
  .benefits-long-section .benefits-long-container .benefit-card img {
    width: 100%;
    height: auto;
  }
}

.benefits-long-section .benefits-long-container .benefit-card .benefit-card__text-box {
  flex-direction: column;
  justify-content: center;
  gap: 2.4rem;
  padding-left: 2rem;
  display: flex;
}

@media (width <= 1200px) {
  .benefits-long-section .benefits-long-container .benefit-card .benefit-card__text-box {
    text-align: left;
    align-items: center;
    padding-left: 0;
  }

  .benefits-long-section .benefits-long-container .benefit-card .benefit-card__text-box h3 {
    text-align: center;
  }
}

.benefits-long-section .benefits-long-container .benefit-card .benefit-card__text-box span {
  font-size: 1.3em;
}

.benefits-long-section .benefits-long-container .benefit-card .benefit-card__text-box button {
  max-width: 22rem;
}

@media (width <= 1200px) {
  .benefits-long-section .benefits-long-container .benefit-card .benefit-card__text-box button {
    width: 200px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  .benefits-long-section .benefits-long-container .benefit-card .benefit-card__text-box button {
    width: 300px;
  }
}

.reviews-section {
  background-color: #000;
}

@media (width <= 1200px) {
  .reviews-section {
    text-align: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .reviews-section p {
    padding: 0 10px;
  }
}

.reviews-section .review-cards-container {
  flex-flow: wrap;
  justify-content: center;
  gap: 3.4rem;
  padding: 5rem 0;
  display: flex;
}

.reviews-section .review-cards-container .review-box {
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 24%;
  min-width: 300px;
  min-height: 500px;
  padding: 2.4rem 2rem;
  display: flex;
}

.reviews-section .review-cards-container .review-box .review-profile-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.6rem;
  display: flex;
}

.reviews-section .review-cards-container .review-box .review-profile-container .review-img-container {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.reviews-section .review-cards-container .review-box .review-profile-container .review-img-container img {
  width: 100%;
  max-width: none;
  height: auto;
}

.reviews-section .review-cards-container .review-box .review-profile-container .profile-name-container {
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.reviews-section .review-cards-container .review-box .review-profile-container .profile-name-container span {
  font-size: 2em;
  font-weight: bold;
}

.reviews-section .review-cards-container .review-box .review-profile-container .profile-name-container .profile-role {
  color: #5e5050c7;
  font-size: 1.2em;
}

.reviews-section .review-cards-container .review-box p {
  color: #300d34;
  padding: 1.8rem 0;
  font-size: 1.1em;
}

.reviews-section .review-cards-container .review-box .review-intro {
  font-size: 2em;
}

.reviews-section .review-cards-container .review-box .review-text {
  display: none;
}

.reviews-section .review-cards-container .review-box:hover .review-profile-container {
  flex-direction: row;
  justify-content: start;
}

.reviews-section .review-cards-container .review-box:hover .review-profile-container .review-img-container {
  width: 120px;
  height: 120px;
}

.reviews-section .review-cards-container .review-box:hover .review-profile-container .profile-name-container {
  text-align: left;
}

.reviews-section .review-cards-container .review-box:hover .review-intro {
  display: none;
}

.reviews-section .review-cards-container .review-box:hover .review-text {
  display: block;
}

.reviews-section .review-cards-container .review-box1 {
  background-color: #7099d7;
}

.reviews-section .review-cards-container .review-box2 {
  background-color: #db7be7;
}

.reviews-section .review-cards-container .review-box3 {
  background-color: #31d7da;
}

.bonuses-section {
  padding-bottom: 0;
}

.bonuses-section .bonuses-container {
  flex-direction: row;
  align-items: center;
  gap: 7rem;
  padding: 4rem 2rem;
  display: flex;
}

@media (width <= 1200px) {
  .bonuses-section .bonuses-container {
    flex-direction: column;
    gap: 5rem;
  }
}

.bonuses-section .bonuses-container img {
  border-radius: 16px;
  height: auto;
}

@media (width <= 1200px) {
  .bonuses-section .bonuses-container img {
    width: 80%;
    height: auto;
  }
}

.bonuses-section .bonuses-container .bonuses-points {
  flex-direction: column;
  justify-content: center;
  gap: 2.4rem;
  max-width: 600px;
  display: flex;
}

.qa-section .accordion-items-container {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.qa-section .accordion-items-container .accordion {
  cursor: pointer;
  border-bottom: 1px solid #ffffff4d;
  width: 100%;
  padding: 0 6px 4px;
  transition: background-color .5s;
}

.qa-section .accordion-items-container .accordion.sky:hover {
  background-color: #31d7da;
}

.qa-section .accordion-items-container .accordion.blue:hover {
  background-color: #7099d7;
}

.qa-section .accordion-items-container .accordion.pink:hover {
  background-color: #db7be7;
}

.qa-section .accordion-items-container .accordion.active.sky {
  background-color: #31d7da;
}

.qa-section .accordion-items-container .accordion.active.blue {
  background-color: #7099d7;
}

.qa-section .accordion-items-container .accordion.active.pink {
  background-color: #db7be7;
}

.qa-section .accordion-items-container .accordion__titlecontainer {
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  width: 100%;
  min-height: 80px;
  padding: 0 12px;
  font-size: 1em;
  display: flex;
  position: relative;
}

.qa-section .accordion-items-container .accordion__titlecontainer .accordion__icon {
  margin-right: 10px;
}

.qa-section .accordion-items-container .accordion__titlecontainer .accordion__icon:after {
  content: "";
  background: #fff;
  width: 2px;
  height: 16px;
  transition: transform .5s ease-out;
  display: block;
  transform: rotate(90deg);
}

.qa-section .accordion-items-container .accordion__titlecontainer .accordion__icon:before {
  content: "";
  background: #fff;
  width: 2px;
  height: 16px;
  transition: transform .5s ease-out;
  display: block;
  position: absolute;
  transform: rotate(0);
}

.qa-section .accordion-items-container .accordion__body {
  will-change: max-height;
  background-color: #130515;
  max-height: 0;
  padding: 0 12px;
  transition: all .5s ease-out;
  overflow: hidden;
}

.qa-section .accordion-items-container .accordion__body p {
  color: #fff;
  white-space: pre-line;
  padding: 1em 0;
  font-size: 1em;
  line-height: 30px;
}

.qa-section .accordion-items-container .accordion.active .accordion__body {
  max-height: 25em;
}

.qa-section .accordion-items-container .accordion.active .accordion__icon:after {
  transform: rotate(270deg) !important;
}

.qa-section .accordion-items-container .accordion.active .accordion__icon:before {
  transform: rotate(-270deg) !important;
}

footer {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  margin: 2rem;
  display: flex;
}

.menu-toggle {
  flex-direction: column;
  justify-content: space-between;
  gap: 0;
  width: 2.1rem;
  height: 1.8rem;
  display: flex;
}

@media (width <= 1200px) {
  .menu-toggle {
    justify-self: center;
    width: 14.5px;
    height: 13px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  .menu-toggle {
    width: 29px;
    height: 26px;
  }
}

.menu-toggle:hover {
  cursor: pointer;
}

.menu-toggle-bar {
  min-width: unset;
  background-color: #92f0f7;
  border-radius: .25rem;
  width: 100%;
  height: .25rem;
  transition: all .35s ease-in-out;
  display: block;
}

@media (width <= 1200px) {
  .menu-toggle-bar {
    height: 2px;
  }
}

@media (width >= 700px) and (width <= 1200px) {
  .menu-toggle-bar {
    height: 4px;
  }
}

.nav-open .menu-toggle-bar {
  width: 145%;
}

.nav-open .menu-toggle-bar.menu-toggle-bar--top {
  transform: translate(0, .77rem)rotate(315deg);
}

@media (width <= 1200px) {
  .nav-open .menu-toggle-bar.menu-toggle-bar--top {
    transform: translate(0, 5.5px)rotate(315deg);
  }
}

@media (width >= 700px) and (width <= 1200px) {
  .nav-open .menu-toggle-bar.menu-toggle-bar--top {
    transform: translate(0, 11px)rotate(315deg);
  }
}

.nav-open .menu-toggle-bar.menu-toggle-bar--middle {
  opacity: 0;
}

.nav-open .menu-toggle-bar.menu-toggle-bar--bottom {
  transform: translate(0, -.77rem)rotate(-315deg);
}

@media (width <= 1200px) {
  .nav-open .menu-toggle-bar.menu-toggle-bar--bottom {
    transform: translate(0, -5.5px)rotate(-315deg);
  }
}

@media (width >= 700px) and (width <= 1200px) {
  .nav-open .menu-toggle-bar.menu-toggle-bar--bottom {
    transform: translate(0, -11px)rotate(-315deg);
  }
}
/*# sourceMappingURL=index.9e3aaee6.css.map */

$menu-blue: #92f0f7;
$font-main: "Gotham Pro";

.form-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: grid;
    place-content: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.905);
    visibility: hidden;

    form {
      display: flex;
      flex-direction: column;
      background-color: #130515f6;
      align-items: center;
      justify-content: start;
      gap: 7vh;
      height: 70vh;
      padding: 5.2rem 1.5rem;
      border-radius: 1rem;
      box-shadow: 1px 1px 14px $menu-blue;
      position: relative;

      @include for-phone-only {
        height: 60vh;
        gap: 2.7rem;
      }

      @include for-large-resolution {
        height: 63vh;
        gap: 6vh;
      }

      @include for-tablet-only {
        gap: 60px;
      }

      .form-close-btn {
        padding: 0;
        line-height: 0;
        background: none;
        justify-self: end;
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px;

        img {
          height: 2.6rem;

          @include for-phone-only {
            height: 30px;
          }
        }
      }

      .form-header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .switch {
          position: relative;
          display: inline-block;
          width: 16em;
          height: 3.3rem;
          justify-self: end;
          align-self: end;

          @include for-phone-only {
            width: 280px;
          }
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: transform 0.4s;
          transition: transform 0.4s;
          border-radius: 0.6rem;
          color: #130515;
          display: flex;
          align-items: center;
          justify-content: end;
          padding: 0 1.4em 0 2.2em;

          @include for-phone-only {
            padding: 0 2em 0 3em;
          }
        }

        .slider.telegram-icon::before {
          content: "";
          background-size: 25%;
          background-image: url("../img/icons/form_icons/telegram-icon.svg");
        }

        .slider.email-icon::before {
          content: "";
          background-image: url("../img/icons/form_icons/email-icon.svg");
        }

        .slider:before {
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          height: 85%;
          width: 8.8em;
          left: 4px;
          bottom: 4px;
          background-color: white;
          -webkit-transition: transform 0.4s;
          transition: transform 0.4s;
          content: "";
          border-radius: 10px;
          content: attr(data-before);
          display: flex;
          align-items: center;
          justify-content: center;

          @include for-phone-only {
            left: 3px;
            bottom: 3px;
            width: 11em;
          }

          @include for-large-resolution {
            left: 7px;
            bottom: 7px;
          }
        }

        input:checked + .slider {
          background-color: #ccc;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px #ccc;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(6.7em);
          -ms-transform: translateX(6.7em);
          transform: translateX(6.7em);

          @include for-phone-only {
            -webkit-transform: translateX(8.5em);
            -ms-transform: translateX(8.5em);
            transform: translateX(8.5em);
          }
        }
      }

      .form-input-container {
        display: flex;
        flex-direction: column;
        gap: 1.7em;

        @include for-tablet-only {
          gap: 30px;
        }

        & label.error {
          .form-input {
            border: 2px solid red;
          }

          .error-text {
            display: block;
            color: red;
            position: absolute;
          }
        }

        .error-text {
          margin-top: 5px;
          display: none;
        }

        .form-input {
          width: 28vw;
          border-radius: 0.6rem;
          font-size: 1.2em;
          line-height: 2em;
          padding: 0.5rem 1.6rem;
          font-family: $font-main;
          color: #130515;

          @include for-phone-only {
            width: 280px;
            font-size: 12px;
            line-height: 35px;
          }

          @include for-tablet-only {
            font-size: 20px;
            width: 500px;
            padding: 12px 26px;
          }
        }
      }

      .form-submit-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.2rem;

        @include for-tablet-only {
          gap: 30px;
        }

        .form-submit-btn {
          max-width: 11rem;
          font-size: 1.1em;
          border-radius: 0.6rem;
          padding: 0.5rem 1.4rem;
          line-height: 2em;
          letter-spacing: 0.24px;
          color: #130515;
          background: #ccc;
          transition: background 0.3s ease-out;
          transition: color 0.3s ease-out;

          @include for-phone-only {
            font-size: 12px;
            padding: 4px 14px;
            line-height: 35px;
          }

          @include for-tablet-only {
            font-size: 18px;
            padding: 8px 24px;
            max-width: 220px;
          }

          &:hover {
            color: #fff;
            background: rgb(237, 32, 210);
            background: linear-gradient(
              90deg,
              rgba(237, 32, 210, 1) 0%,
              rgba(49, 215, 218, 1) 87%
            );
          }
        }

        .form-tg-link {
          text-align: center;
          line-height: 2em;
          letter-spacing: 0.24px;
          white-space: nowrap;
          font-weight: bold;
          display: none;

          @include for-phone-only {
            line-height: 35px;
          }
        }

        label {
          text-align: center;
          max-width: 30vw;
          font-size: 0.8em;

          @include for-phone-only {
            font-size: 10px;
            max-width: 400px;
          }

          @include for-tablet-only {
            font-size: 14px;
          }
        }
      }
    }

    &.form-active {
      visibility: visible;
    }

    &.form-tg-mode {
      form {
        gap: 5vh;

        .form-input-container {
          display: none;
        }

        .form-submit-container {
          gap: 2em;

          .email-submit-btn {
            display: none;
          }

          .form-tg-link {
            display: block;
          }
        }
      }
    }
  }
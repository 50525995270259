@import url("/src/fonts/oneday/oneday-font.css");
@import url("/src/fonts/Cyrillic/multitypes-lines-columns/multilines-font.css");
@import url("/src/fonts/gotham_pro/gotham-pro-font.css");

@import "./mixins.scss";
@import "./form.scss";

$font-logo: "One Day";
$font-h1-ru: "Multilines";
$font-main: "Gotham Pro";
$section-headline-size: 2.6em;
$mobile-section-headline-s: 1.9em;

$gradient1: -webkit-linear-gradient(right, #31d7da, #ed20d2);
$gradient2: -webkit-linear-gradient(right, #31d7da, #ed20d2);

$background-color: #130515;
$menu-blue: #92f0f7;
$logo-blue: #cae0e8;

@property --a {
  syntax: "<angle>";
  inherits: false;
  initial-value: 10deg;
}

@property --p {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 20%;
}

@property --c {
  syntax: "<color>";
  inherits: false;
  initial-value: rgba(237, 32, 210, 1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  color: #fff;
  font-family: $font-main;

  @include for-phone-only {
    font-size: 14px;
  }

  @include for-large-resolution {
    font-size: 26px;
  }
}

body {
  background-color: $background-color;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
  margin-top: 5px;
  margin-bottom: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: $logo-blue;
  border: none;
  border-radius: 10px;
}

button {
  border-radius: 2em;
  border: none;
  font-size: 24px;
  line-height: 1.5em;
  letter-spacing: 0.24px;
  white-space: nowrap;
  padding: 0.8rem 2.6rem;
  font-weight: bold;
  font-family: $font-main;
  color: #fff;
  --a: 10deg;
  --c: rgba(237, 32, 210, 1);
  background: --c;
  background: linear-gradient(90deg, var(--c), rgba(49, 215, 218, 1) 87%);
  transition: --a 0.5s, --c 0.5s;

  @include for-phone-only {
    font-size: 16px;
    padding: 0.8rem 1.8rem;
    max-width: 320px;
  }

  @include for-tablet-only {
    font-size: 20px;
    padding: 1.3rem 2.8rem;
    max-width: 400px;
  }

  @include for-large-resolution {
    font-size: 38px;
  }
}

button:hover {
  cursor: pointer;
  --c:rgba(74, 196, 28, 1);
}

a {
  text-decoration: none;
}

.main-container {
  position: relative;
  z-index: 0;
}

header {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 10fr 1fr 1fr;
  gap: 3.8rem;
  padding: 1.2rem;
  font-size: 1.2rem;
  align-items: center;
  min-width: 100%;
  background-color: $background-color;

  @include for-phone-only {
    padding: 1rem;
    gap: 1.8rem;
  }

  a {
    justify-self: start;
    display: flex;
    align-items: center;
    gap: 1rem;

    @include for-phone-only {
      gap: 8px;
    }

    @include for-tablet-only {
      gap: 16px;
    }

    img {
      height: 3rem;

      @include for-phone-only {
        height: 28px;
      }

      @include for-tablet-only {
        height: 48px;
      }
    }

    span {
      min-width: 200px;
      color: $logo-blue;
      font-family: $font-logo;
      font-size: 1.6rem;
      font-weight: bold;

      @include for-phone-only {
        min-width: 100px;
        font-size: 11px;
      }

      @include for-tablet-only {
        font-size: 20px;
      }
    }
  }

  button {
    justify-self: end;
    align-self: center;
    padding: 0;
    background: none;
    display: flex;

    img {
      height: 2.5rem;

      @include for-phone-only {
        height: 20px;
      }

      @include for-tablet-only {
        height: 40px;
      }
    }
  }

  .logo-link {
    padding-left: 2.5rem;

    @include for-phone-only {
      padding-left: 18px;
    }
  }

  .languages-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: end;

    ul {
      background-color: #5c207d40;
      position: absolute;
      z-index: 100;
      top: 5rem;
      font-size: 1.1rem;
      list-style: none;
      border-radius: 1.1rem;
      padding: 1.1rem 1.7rem;
      display: flex;
      flex-direction: column;
      gap: 1.1rem;
      visibility: hidden;

      @include for-phone-only {
        top: 50px;
        font-size: 9px;
      }

      @include for-tablet-only {
        top: 80px;
        font-size: 18px;
      }

      li {
        padding: 0 px;

        a {
          padding-left: 0;
          color: #fff;
        }
      }
    }
  }
}

nav {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 1fr;
  background-color: $background-color;
  min-height: 100%;
  padding: 20px 100px;
  position: fixed;
  top: 8vh;
  z-index: 1;
  min-width: 100%;
  visibility: hidden;
  overflow-y: auto;

  @include for-phone-only {
    top: 5vh;
    padding: 0 18px;
  }

  a {
    color: #fff;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;
    list-style: none;

    @include for-phone-only {
      gap: 55px;
    }

    a {
      font-size: 2.8rem;
      padding-left: 20px;
      // transition: all 1s ease-in-out;

      @include for-phone-only {
        font-size: 2.2rem;
        padding-left: 8px;
      }

      &:hover {
        background: -webkit-linear-gradient(right, #31d7da, #ed20d2);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    li {
      img {
        opacity: 0;

        @include for-phone-only {
          height: 17px;
        }
      }

      .navlink-gradient2:hover {
        background: rgb(74, 196, 28);
        background: -moz-linear-gradient(
          90deg,
          rgba(74, 196, 28, 1) 2%,
          rgba(49, 215, 218, 1) 100%
        );
        background: -webkit-linear-gradient(
          90deg,
          rgb(74, 196, 28) 2%,
          rgba(49, 215, 218, 1) 100%
        );
        background: linear-gradient(
          90deg,
          rgba(74, 196, 28, 1) 2%,
          rgba(49, 215, 218, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4ac41c",endColorstr="#31d7da",GradientType=1);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .navlink-gradient3:hover {
        background: rgb(255, 142, 12);
        background: -moz-linear-gradient(
          90deg,
          rgba(255, 142, 12, 1) 22%,
          rgba(49, 215, 218, 1) 100%
        );
        background: -webkit-linear-gradient(
          90deg,
          rgba(255, 142, 12, 1) 22%,
          rgba(49, 215, 218, 1) 100%
        );
        background: linear-gradient(
          90deg,
          rgba(255, 142, 12, 1) 22%,
          rgba(49, 215, 218, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8e0c",endColorstr="#31d7da",GradientType=1);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }

  .nav-footer {
    font-size: 1.4rem;
    justify-self: end;
    height: 90px;

    a {
      display: flex;
      flex-direction: row;
      gap: 14px;
      align-items: center;

      @include for-phone-only {
        gap: 9px;
      }

      img {
        height: 36px;

        @include for-phone-only {
          height: 24px;
        }
      }
    }
  }
}

main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 92vh;
  padding: 20px 20px 40px 4%;
  gap: 2rem;

  @include for-phone-only {
    flex-direction: column;
    justify-content: space-around;
    padding: 50px 20px 20px 20px;
    gap: 60px;
  }

  @include for-tablet-only {
    padding-top: 100px;
  }

  .main-text-container {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    max-width: 44vw;
    text-align: left;
    justify-content: center;

    @include for-phone-only {
      max-width: 90%;
      align-items: center;
    }

    @include for-large-resolution {
      max-width: 1200px;
    }

    @include for-tablet-only {
      font-size: 20px;
      gap: 4em;
    }

    .main-heading-container {
      margin-bottom: 100px;
      height: 100px;

      @include for-large-resolution {
        margin-bottom: 260px;
      }

      h1 {
        font-size: 2.8em;

        @include for-phone-only {
          font-size: 2em;
        }
      }

      span {
        font-size: 2.8em;
        font-weight: bold;
        background: -webkit-linear-gradient(right, #31d7da, #ed20d2);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @include for-phone-only {
          font-size: 2em;
        }

        &.typed-style-0 {
          background: -webkit-linear-gradient(right, #31d7da, #ed20d2);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &.typed-style-1 {
          background: -webkit-linear-gradient(right, #4ac41c, #31d7da);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &.typed-style-2 {
          background: -webkit-linear-gradient(right, #ff8e0c, #31d7da);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    p {
      font-size: 1.3em;
      font-optical-sizing: auto;

      @include for-phone-only {
        text-align: center;
      }
    }

    .main-register-btn {
      // transition: --c .3s ease;
      animation: --c 20s ease infinite;
      --с: rgb(237, 32, 210);
      --p: 20%;
      background: --c;
      background-size: 100%;
        background: linear-gradient(
          90deg,
          var(--c) 0%,
          rgba(49, 215, 218, 1) 87%
        );
        background: -moz-linear-gradient(
            90deg,
            --c 0%,
            rgba(49, 215, 218, 1) 87%
          );
          background: -webkit-linear-gradient(
            90deg,
            --c 0%,
            rgba(49, 215, 218, 1) 87%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4ac41c",endColorstr="#31d7da",GradientType=1);

      @include for-phone-only {
        font-size: 13px;
      }

      @include for-tablet-only {
        font-size: 20px;
        max-width: 500px;
      }

      &.main-btn-style-0 {
        --c: rgb(237, 32, 210);
        background: --c;
        background: linear-gradient(
          90deg,
          var(--c) 0%,
          rgba(49, 215, 218, 1) 87%
        );
      }

      &.main-btn-style-1 {
        --c: rgb(74, 196, 28);
        background: --c;
        background: linear-gradient(
          90deg,
          var(--c) 2%,
          rgba(49, 215, 218, 1) 100%
        );
      }

      &.main-btn-style-2 {
        --c: rgb(255, 142, 12);
        background: --c;
        background: linear-gradient(
          90deg,
          var(--c) 22%,
          rgba(49, 215, 218, 1) 100%
        );
      }
    }
  }

  .video-overlay {
    position: absolute;
    top: 18%;
    left: 26%;
    width: 51.5%;
    height: 60vh;
    align-content: center;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }

  .video-container {
    margin-bottom: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    width: 100%;

    @include for-phone-only {
      max-width: 90%;
    }

    img {
      max-height: 60vh;
      width: 100%;
      @include for-phone-only {
        height: auto;
        overflow-x: hidden;
      }
    }

    iframe {
      height: 52%;
      width: 52%;
      position: absolute;
      top: 72px;
      left: 180px;

      @include for-phone-only {
        top: 40px;
        left: 90px;
      }
    }
/*
    video {
      position: absolute;
      height: 46%;
      margin-top: -5rem;
      margin-left: 1.2rem;

      @include for-phone-only {
        margin-left: 0.6rem;
        margin-top: -2.4rem;
        max-width: 51%;
      }

      @include for-tablet-only {
        margin-top: -5.6rem;
        max-width: 52%;
        margin-left: 1.6rem;
      }

      @include for-large-resolution {
        margin-top: -5rem;
        margin-left: 1.3rem;
        max-width: 60%;
      }
    }*/
  }
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;

  @include for-phone-only {
    padding: 40px 18px;
  }

  h2 {
    font-size: $section-headline-size;
    text-align: center;

    @include for-phone-only {
      font-size: $mobile-section-headline-s;
    }
  }

  h3 {
    font-size: 1.8em;
  }

  img {
    max-width: 40vw;
  }
}

.awards-section {
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 5% 8% 2% 8%;
  background-color: #000;

  @include for-phone-only {
    gap: 2rem;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    padding: 3rem 4rem;
  }

  img {
    width: 5rem;

    @include for-phone-only {
      width: 60px;
      justify-self: center;
    }
  }
}

.benefits-section-short {
  gap: 6rem;
  background-color: #000;

  @include for-phone-only {
    gap: 4rem;
  }

  .benefits-short-container {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    gap: 2.3rem;
    text-align: center;
    color: #000;

    @include for-phone-only {
      display: flex;
      flex-direction: column;
      width: 80%;
    }

    div {
      align-self: center;
      padding: 2rem 1rem;
      border-radius: 1em;
      background-color: #fff;
      height: 160px;
      width: 18vw;
      position: relative;
      bottom: 0;
      right: 0;
      transition: all 0.3s ease-in;

      @include for-phone-only {
        height: 130px;
        width: 100%;
        padding: 2.5rem 1rem;
      }

      @include for-large-resolution {
        font-size: 0.7em;
        height: 200px;
      }

      svg {
        position: absolute;
        height: 3.4rem;
        right: 0.4rem;
        bottom: 0.1rem;

        @include for-phone-only {
          height: 4rem;
        }

        @include for-large-resolution {
          height: 2.7rem;
        }
      }
    }

    div:hover {
      box-shadow: 5px 5px 26px $menu-blue;
      position: relative;
      bottom: 8px;
      right: 8px;
      color: #b0109af3;

      svg {
        fill: #53b1b3bd;
        stroke: #53b1b3bd;

        g {
          fill: #53b1b3bd;
        }
      }

      .trend-svg {
        path {
          stroke: #53b1b3bd;
        }
      }
    }
  }
}

.benefits-long-section {
  .benefits-long-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.8rem 0;
    gap: 2.4rem;

    .benefit-card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 2.4rem;
      padding: 2rem;
      background-color: #fff;
      border-radius: 1.5rem;
      color: #130515;
      max-width: 74vw;

      @include for-phone-only {
        flex-direction: column;
      }

      img {
        height: 56vh;
        border-radius: 1.5rem;

        @include for-phone-only {
          // height: 30vh;
          width: 100%;
          height: auto;
        }
      }

      .benefit-card__text-box {
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2.4rem;

        @include for-phone-only {
          padding-left: 0;
          align-items: center;
          text-align: left;

          h3 {
            text-align: center;
          }
        }

        span {
          font-size: 1.3em;
        }

        button {
          max-width: 22rem;

          @include for-phone-only {
            width: 200px;
          }

          @include for-tablet-only {
            width: 300px;
          }
        }
      }
    }
  }
}

.reviews-section {
  background-color: #000;

  @include for-phone-only {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      padding: 0 10px;
    }
  }

  .review-cards-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3.4rem;
    padding: 5rem 0;
    flex-wrap: wrap;

    .review-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 24%;
      border-radius: 16px;
      padding: 2.4rem 2rem;
      gap: 2rem;
      min-width: 300px;
      min-height: 500px;
      // transition: all .2s ease-in-out;

      .review-profile-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2.6rem;

        .review-img-container {
          width: 200px;
          height: 200px;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 100%;
            height: auto;
            max-width: none;
          }
        }

        .profile-name-container {
          display: flex;
          flex-direction: column;
          text-align: center;
          gap: 1rem;

          span {
            font-weight: bold;
            font-size: 2em;
          }

          .profile-role {
            font-size: 1.2em;
            color: #5e5050c7;
          }
        }
      }

      p {
        color: #300d34;
        padding: 1.8rem 0;
        font-size: 1.1em;
      }

      .review-intro {
        font-size: 2em;
      }

      .review-text {
        display: none;
      }
    }

    .review-box:hover {
      // scale: 1.1;

      .review-profile-container {
        flex-direction: row;
        justify-content: start;

        .review-img-container {
          width: 120px;
          height: 120px;
        }

        .profile-name-container {
          text-align: left;
        }
      }

      .review-intro {
        display: none;
      }

      .review-text {
        display: block;
      }
    }

    .review-box1 {
      background-color: #7099d7;
    }

    .review-box2 {
      background-color: #db7be7;
    }

    .review-box3 {
      background-color: #31d7da;
    }
  }
}

.bonuses-section {
  padding-bottom: 0;

  .bonuses-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4rem 2rem;
    gap: 7rem;

    @include for-phone-only {
      flex-direction: column;
      gap: 5rem;
    }

    img {
      height: auto;
      border-radius: 16px;

      @include for-phone-only {
        width: 80%;
        height: auto;
      }
    }

    .bonuses-points {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2.4rem;
      max-width: 600px;
    }
  }
}

.qa-section {
  .accordion-items-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .accordion {
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
      width: 100%;
      cursor: pointer;
      transition: background-color 0.5s;
      padding: 0 6px 4px;

      &.sky:hover {
        background-color: #31d7da;
      }

      &.blue:hover {
        background-color: #7099d7;
      }

      &.pink:hover {
        background-color: #db7be7;
      }

      &.active.sky {
        background-color: #31d7da;
      }

      &.active.blue {
        background-color: #7099d7;
      }

      &.active.pink {
        background-color: #db7be7;
      }

      &__titlecontainer {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 80px;
        font-size: 1em;
        gap: 6px;
        padding: 0 12px;

        .accordion__icon {
          margin-right: 10px;
          &::after {
            content: "";
            display: block;
            background: #fff;
            width: 2px;
            height: 16px;
            transform: rotate(90deg);
            transition: transform 0.5s ease-out;
          }

          &::before {
            position: absolute;
            content: "";
            display: block;
            background: #fff;
            width: 2px;
            height: 16px;
            transform: rotate(0deg);
            transition: transform 0.5s ease-out;
          }
        }
      }

      &__body {
        max-height: 0;
        transition: all 0.5s ease-out;
        will-change: max-height;
        overflow: hidden;
        background-color:#130515;
        padding: 0 12px;

        p {
          padding: 1em 0;
          font-size: 1em;
          line-height: 30px;
          color: #fff;
          white-space: pre-line;
        }
      }

      &.active .accordion__body {
        max-height: 25em;
      }

      &.active .accordion__icon {
        &::after {
          transform: rotate(270deg) !important;
        }

        &::before {
          transform: rotate(-270deg) !important;
        }
      }
    }
  }
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem;
  text-align: center;
  gap: 1.5rem;
}

.menu-toggle {
  height: 1.8rem;
  width: 2.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0px;

  @include for-phone-only {
    height: 13px;
    width: 14.5px;
    justify-self: center;
  }

  @include for-tablet-only {
    height: 26px;
    width: 29px;
  }

  &:hover {
    cursor:pointer;
  }
}

.menu-toggle-bar {
  display: block;
  min-width: unset;
  width: 100%;
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: $menu-blue;
  transition: all 0.35s ease-in-out;

  @include for-phone-only {
    height: 2px;
  }

  @include for-tablet-only {
    height: 4px;
  }

  .nav-open & {
    width: 145%;
    &.menu-toggle-bar--top {
      transform: translate(0, 0.77rem) rotate(315deg);

      @include for-phone-only {
        transform: translate(0, 5.5px) rotate(315deg);
      }

      @include for-tablet-only {
        transform: translate(0, 11px) rotate(315deg);
      }
    }
    &.menu-toggle-bar--middle {
      opacity: 0;
    }
    &.menu-toggle-bar--bottom {
      transform: translate(0, -0.77rem) rotate(-315deg);

      @include for-phone-only {
        transform: translate(0, -5.5px) rotate(-315deg);
      }

      @include for-tablet-only {
        transform: translate(0, -11px) rotate(-315deg);
      }
    }
  }
}
